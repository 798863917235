<template>
  <div class="step-content">
    <div class="content">
      <div class="title">
        <div class="l-title">成为服务机构，你可以：</div>
        <div class="r-title">
          <div v-for="item in imgList" :key="item.url">
            <img :src="item.url" />
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="agreement">
        <h1 style="text-align:center">申报通和服务机构赋能系统账号服务协议</h1>
        <p style="color:#000000;opacity:0.6;text-align:center">编号：SBT-JG-FS-2021.8.1</p>
        &emsp;&emsp;欢迎您使用我们的平台！本申报通和服务机构赋能系统账号服务协议（下称“本协议”）是您在使用政策服务云平台（www.po-o.cn）
        服务机构赋能系统时所应遵守的条款及条件。本协议由广东政沣云技术有限公司（下称“政沣公司”）与您所代表的实体（下称“用户”或“您”）签订。
        您向我们声明并保证，您具有合法代表该实体之权限且有可约束该实体遵守本协议条款与条件之合法权限。
        本协议自您接受之日起生效，请您务必审慎阅读、充分理解本协议内容。如您对本协议有任何疑问，可咨询客服。如您不同意本协议中任何条款，请停止注册。<br>
        同意本协议代表您同意采购申报通和服务机构赋能系统账号服务，用于挖掘客户潜力、开展增值服务。为明确有关事宜，订立合同内容如下。
        <p style="font-size: 16px;color:#000;font-weight: bold">第一条 有关名词术语释义如下</p>
        （一）申报通：是指申报通|政策服务云平台（www.po-o.cn，含网站、微信等终端）。主要包括【政策文库】【政策词典】【项目匹配】【智能推送】【申报管家】等功能模块。<br>
        （二）服务机构赋能系统：是指申报通供服务机构专用的一种赋能型软件和数据服务子系统。服务机构是指咨询、专利、审计、认证、会展、软件、人才等方面服务提供商。<br>
        （三）企业用账号：是指企事业单位操作使用申报通的权限。企业用账号分为普通用户、会员，账号费的收费标准见申报通网站。释义如下：<br>
        1．普通用户：是指注册开通申报通部分功能的免费用户。<br>
        2．会员：是指注册开通申报通全部功能的付费用户<br>
        （四）机构用账号：是指服务机构操作使用申报通和服务机构赋能系统的权限。机构用账号分为管理员账号、业务员账号、准账号。释义如下：<br>
        1．管理员账号：归机构管理人员使用。主要用于客户管理、业务员管理、结算管理等。<br>
        2．业务员账号：归机构业务员（客户服务或销售人员）使用，用于操作准账号。<br>
        3．准账号：机构业务员为客户提供数据处理、项目匹配、信息发送等一系列功能的统称。一家客户配一个准账号。不同客户数据，录入不同准账号。<br>
        （五）项目：是指政策文件中列明的扶持项目。<br>
        <p style="font-size: 16px;color:#000;font-weight: bold">第二条 机构用账号的组成、数量、开通办法、有效期、收费标准</p>
        （一）一套机构用账号包括管理员账号1个、业务员账号若干、准账号若干。<br>
        （二）管理员账号由政沣公司开通，有效期为长期，免费。<br>
        （三）业务员账号由您自主开通，有效期为长期，免费。<br>
        （四）准账号由您管理员开通，默认有效期为1年（365天|自开通或续费之日起算），收费标准见申报通和服务机构赋能系统。<br>
        <p style="font-size: 16px;color:#000;font-weight: bold">第三条 企业账号结算</p>
        如您向客户销售企业用账号，客户缴纳的账号费的80%归您所有。每月结算一次。<br>
        <p style="font-size: 16px;color:#000;font-weight: bold"> 第四条 您负责的主要事项</p>
        （一）指定现登录平台的手机号码为服务机构账号，用于管理员账号注册和接收消息、验证码、公告或通知等。<br>
        （二）提供真实有效的证照（营业执照或法人登记证的复印件/扫描件）用于开通管理员账号。<br>
        <p style="font-size: 16px;color:#000;font-weight: bold"> 第五条 政沣公司负责的主要事项</p>
        （一）政沣公司在收到您申请资料并审核无误后，开通管理员账号给您。<br>
        （二）通过演示、电话、QQ等方式辅导您学会申报通和服务机构赋能系统操作。<br>
        （三）通过在线客服、客服热线（4006581139）等方式为您提供软件操作咨询、报障、投诉等服务。服务时间为国家法定工作日上午8:30-12:00和下午13:30-17:30。如出现系统故障，政沣公司承诺在接到您报障后2个工作日内处理完毕。<br>
        <p style="font-size: 16px;color:#000;font-weight: bold"> 第六条 保密约定</p>
        双方对本合同内容和对方商业秘密均有保密义务。未事先取得对方书面同意，不得以任何形式向任何第三方披露，否则应承担因泄密所引发的法律后果和相关责任。<br>
        <p style="font-size: 16px;color:#000;font-weight: bold"> 第七条 违约责任</p>
        （一）双方均应自觉执行本合同，如未按合同约定办理有关事项，应承担相应违约责任。双方一致同意遵守《政策服务云平台用户协议》（详见申报通和服务机构赋能系统），申报通的操作记录及其数据可以作为合同执行情况的依据。<br>
        （二）如政沣公司未按时开通账号给您，应全额退还已收取的账号费。<br>
        （三）因不可抗力造成本合同无法履行时，双方互不承担责任。不可抗力包括国家政策文件发生调整，或发生地震、洪水、火灾、瘟疫、战争、暴乱等人力不可控情况。<br>
        <p style="font-size: 16px;color:#000;font-weight: bold"> 第八条 争议处理</p>
        双方在合同执行与合作过程中，如发生争议，应先尽量友好协商解决，如协商不成，可向佛山市人民法院提起诉讼。<br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notice',
  data () {
    return {
      imgList: [
        {
          url: require('../../../../assets/pc/images/agencies/maintain.png'),
          title: '维护客户资料'
        },
        {
          url: require('../../../../assets/pc/images/agencies/matching.png'),
          title: '匹配客户项目'
        },
        {
          url: require('../../../../assets/pc/images/agencies/promote.png'),
          title: '促进申报业务'
        },
        {
          url: require('../../../../assets/pc/images/agencies/management.png'),
          title: '管理业务人员'
        }
      ]
    };
  },
  computed: {
    // stepNum() {
    //   return this.$store.state.agencies.stepNum;
    // },

  }
};
</script>

<style lang="less" scoped>
.step-content {
  width: 100%;
  margin-top: 41px;
  margin-bottom: 45px;

  .content {
    width: 1028px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 auto;
    .title {
      height: 160px;
      display: flex;
      align-items: center;
      background: linear-gradient(90deg, #f4f8fc 0%, #eaf2fc 100%);
      box-shadow: 0px 38px 92px 0px #edf2fa;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 1px solid #ccc;
      .l-title {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-style: italic;
        color: #000000;
        opacity: 0.9;
        margin-left: 30px;
        margin-right: 161px;
      }
      .r-title {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 76px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6a81a8;
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          > img {
            margin-bottom: 12px;
            // border: 1px dashed #666;
          }
        }
      }
    }
    .agreement {
      padding: 38px;
      background-color: #fff;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      opacity: 0.8;
    }
  }
}
</style>
