<template>
  <div class="agencies">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>服务机构申请</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="matter">
      <div v-for="(item, index) in stepList" :key="index" class="stepList">
        <div class="step">
          <div class="progress" v-show="index <= stepNum">
            <div>{{ item.num }}</div>
          </div>
          <div v-show="index > stepNum" class="unProgress">{{ item.num }}</div>
          <div
            class="step-title"
            :style="
              index <= stepNum ? 'color:#156ED0' : 'color:#000000;opacity: 0.6;'
            "
          >
            {{ item.name }}
          </div>
        </div>
        <div class="step-line" v-show="index < 3"></div>
      </div>
    </div>
    <Notice v-show="stepNum == 0"></Notice>
    <Apply ref="apply" v-show="stepNum == 1"></Apply>
    <Platform v-show="stepNum == 2"></Platform>
    <div style="text-align: center; margin-top: 60px" v-show="stepNum !== 2">
      <el-result v-if="result === 'success' && stepNum == 3" icon="success" title="恭喜，你的服务机构申请已通过"></el-result>
      <el-result v-if="result === 'fail' && stepNum == 3" icon="error" title="抱歉，您的服务机构申请未通过" :subTitle="subTitle"></el-result>
      <el-button type="primary" @click="handleClick">{{stepNum == 0?'同意，去申请':stepNum == 1?'确认无误，提交':stepNum == 3&&result === 'success'?'去使用':stepNum == 3&&result === 'fail'?'再次申请':''}}</el-button>
    </div>
  </div>
</template>

<script>
import Notice from './components/Notice.vue';
import Apply from './components/Apply.vue';
import Platform from './components/Platform.vue';
import { request } from '@/network/index';
export default {
  components: {
    Notice,
    Apply,
    Platform
  },
  data () {
    return {
      stepList: [
        { num: '1', name: '申请须知' },
        { num: '2', name: '提交申请资料' },
        { num: '3', name: '平台审核' },
        { num: '4', name: '申请结果' }
      ],
      subTitle: ''
    };
  },
  computed: {
    stepNum () {
      return this.$store.state.agencies.stepNum;
    },
    result () {
      return this.$store.state.agencies.result;
    }
  },
  watch: {
    result (val) {
      val === 'fail' && this.getFail();
    }
  },
  mounted () {
    window.addEventListener('beforeunload', () => {
      window.sessionStorage.setItem('agencies', JSON.stringify(this.$store.state.agencies));
    });
    const agenciesData = JSON.parse(window.sessionStorage.getItem('agencies'));
    agenciesData && this.$store.commit('agencies/getSessionData', agenciesData);
  },
  methods: {
    handleClick () {
      if (this.stepNum === 1) {
        this.$refs.apply.submit().then((data) => {
          !data && this.$message.warning('请完善主体相关信息', '提示');
          data && this.$store.commit('agencies/setStepNum');
        });
      }
      if (this.stepNum < 3 && this.stepNum !== 1) this.$store.commit('agencies/setStepNum');
      this.stepNum === 3 && this.result === 'success' && this.changeStatus() && window.open('https://ctxt.po-o.cn/#/backstage', '_blank');
      this.stepNum === 3 && this.result === 'fail' && this.$refs.apply.getList() && this.$store.commit('agencies/changeStepNum', 1);
    },
    // 修改机构状态
    async changeStatus () {
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/org/changeStatus',
        data: {
          memberId: JSON.parse(window.localStorage.getItem('userinfo')).id,
          orgId: JSON.parse(window.localStorage.getItem('userinfo')).orgId
        }
      });
      if (parseInt(res.code) !== 200) return this.$message.error('修改状态失败');
    },
    async getFail () {
      const { data: res } = await request({
        method: 'get',
        url: '/pcp/org/getAuditRemark',
        params: {
          orgId: JSON.parse(window.localStorage.getItem('userinfo')).orgId
        }
      });
      if (parseInt(res.code) === 200 && res.data) return this.subTitle === res.data;
    }
  }
};
</script>

<style lang="less" scoped>
.agencies {
  padding: 43px 0px;
  background-color: #fff;
  .el-breadcrumb{
    margin-bottom: 54px;
  }
   .matter {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    .stepList {
      display: flex;
      // align-items: center;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      position: relative;
      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 96px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        .progress {
          width: 40px;
          height: 40px;
          background: #ccdfff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          > div {
            width: 30px;
            height: 30px;
            background: #156ED0;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            line-height: 30px;
          }
        }
        .unProgress {
          width: 40px;
          height: 40px;
          background: #dbe1eb;
          border-radius: 50%;
          text-align: center;
          line-height: 40px;
          color: #fff;
        }
        .step-title {
          margin-top: 11px;
        }
      }
      .step-line {
        height: 1px;
        width: 220px;
        background: #dbe1eb;
        position: absolute;
        top: 20px;
        left: 67px;
      }
    }
  }
}
</style>
