<template>
    <div class="platform">
        <div><img :src="logo"></div>
        <div>您的资料已提交成功</div>
        <div>平台正在审核中，预计3-5个工作日内处理完成</div>
    </div>
</template>

<script>
export default {
  name: 'Platform',
  data () {
    return {
      logo: require('@/assets/pc/images/agencies/submitted.png')
    };
  }
};
</script>

<style lang="less" scoped>
    .platform{
        text-align: center;
         >div:nth-child(2){
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            opacity: 0.9;
            margin-top: 31px;
            margin-bottom: 16px;
        }
        >div:nth-child(3){
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            opacity: 0.6;
        }
    }
</style>
